import React from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { lv } from "date-fns/locale";
import { getAuthToken, getUserPermissionsByToken } from "../Authentication";
import { fetchPost, fetchGet } from "../Fetch.js";
import Branches from "./Branches";
import SchedulerAlert from "./SchedulerAlert";
import CustomEditor from "./SchedulerEditor";

var moment = require("moment-timezone");

const month = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 1,
  startHour: 9,
  endHour: 21,
};

const week = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 1,
  startHour: 9,
  endHour: 21,
  step: 60,
};

const day = {
  startHour: 9,
  endHour: 21,
  step: 60,
};


let authTokenGlobal = null;

const getReservations = (studio) =>
  fetchGet(`/api/reservation/${authTokenGlobal}/${studio}/`);

const postReservation = (event) => {
  const payload = {
    title: event.title,
    datetime_start: event.start,
    datetime_finish: event.end,
    auth_token: authTokenGlobal,
  };
  const url = `/api/reservation/${authTokenGlobal}/`;
  fetchPost(payload, url);
};

const submitEventHandler = async (event, action) => {
  // if (action === "edit")
  if (action === "create") {
    await postReservation(event);
  }
  return new Promise((res, rej) => {
    res({ ...event, event_id: event.event_id || Math.random() });
  });
};

const dtToRigaTimezone = (dt_str) => {
  /*
  let dt = moment(dt_str).toDate();
  const offset = dt.getTimezoneOffset();
  const minutesToAdd = offset + 180;
  dt = moment(dt).add(minutesToAdd, "m").toDate();
  */
  return moment(dt_str).utc(false).toDate();
};

export default class SchedulerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      authToken: null,
      studioSelected: null,
      rehearsalTypes: [],
      additionalServices: [],
      alertChosenPast: false,
      isAdminUser: false,
    };
  }

  authenticate = async () => {
    if (this.state.authToken) return;
    let isAdminUser = false;

    const token = getAuthToken();
    this.state.authToken = token;
    authTokenGlobal = token;

    if (token) {
      const permissions = await getUserPermissionsByToken(token);
      if (permissions.staff) {
        isAdminUser = true;
        this.setState({isAdminUser: true})
      }

    }

    // if (!isAdminUser) {
    //   Array.from(document.getElementsByClassName("rs__cell")).forEach((e) => {
    //     e.style.setProperty("pointer-events", "none");
    //   });
    // }
  
  };

  translateLV = () => {
    const translations = {
      month: "menesis",
      week: "nedela",
      day: "diena",
    };
  
    for (const [key, value] of Object.entries(translations)) {
      const xpath = `//button[text()='${key}']`;
      const matchingElement = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (matchingElement) matchingElement.innerText = value;
    }
  
    this.amPMtoNormal("span")
  
  };

  getRehearsalTypes = () => fetchGet('/api/rehearsal_types/')
  setRehearsalTypes = async () => {
    const response = await this.getRehearsalTypes()
    this.setState({rehearsalTypes: response})
  }

  getRehearsalAdditionalServices = () => fetchGet('/api/rehearsal_additional_services/')
  setAdditionalServices = async () => {
    const response = await this.getRehearsalAdditionalServices()
    this.setState({additionalServices: response})
  }

  populateReservations = async (studio) => {
    if (this.state.reservations.length > 0 && !studio) {
      return this.setState({ reservations: []});
    }

    if (!studio) {
      if (!this.state.studioSelected) studio = null;
      if (this.state.studioSelected) {
        return this.setState({ reservations: []});
      };
    }

    const res = await getReservations(studio);
    if (!res || res.length === 0) {
      return this.setState({ reservations: []});
    }

    let resultArray = [];
    res.forEach((e) => {
      resultArray.push({
        event_id: e.id,
        title: e.title,
        start: dtToRigaTimezone(e.datetime_start),
        end: dtToRigaTimezone(e.datetime_finish),
      });
    });
    
    this.setState({ reservations: resultArray}, () => this.disableEventEls());
  };

  studioSetter = (studio) => {
    this.setState({studioSelected: studio}, () => {this.populateReservations(studio)})
    
  };

  disableCells() {
    Array.from(document.getElementsByClassName("rs__cell")).forEach((e) => {
        e.style.setProperty("pointer-events", "none");
    });
  }

  disableEventEls() {
    Array.from(document.getElementsByClassName("rs__event__item")).forEach((e) => {
      e.style.setProperty("pointer-events", "none");
    });
    this.amPMtoNormal("p")
  }

  amPMtoNormal(elTag) {
    for (const el of document.querySelectorAll(elTag)) {

      let resultArr = []
      let innerHTMLtext = [el.innerHTML]
      if (elTag === "p") innerHTMLtext = el.innerHTML.split("-")
      innerHTMLtext = el.innerHTML.split(" - ")

      for (const str of innerHTMLtext) {
        if (str.includes(" pm")) {
          let hours = str.substring(0, 2)
          let minutes = str.substring(3, 6)
          if (!isNaN(hours)) {
            if (hours !== "12") hours = parseInt(hours)+12
            resultArr.push(`${hours}:${minutes}`)
          }
        } else if (str.includes(" am")) {
          let hours = str.substring(0, 2)
          let minutes = str.substring(3, 6)
          if (!isNaN(hours)) {
            resultArr.push(`${hours}:${minutes}`)
        }
        }
      }

      if (resultArr.length === 2) {
        el.innerHTML = `${resultArr[0]} - ${resultArr[1]}`
      } else if (resultArr.length === 1) {
        el.innerHTML = `${resultArr[0]}`
      }

    }
  }

  componentDidMount() {
    this.authenticate();
    this.setRehearsalTypes();
    this.setAdditionalServices();
    this.populateReservations();
    setTimeout(() => this.translateLV(), 0);
  }

  render() {
    return (
      <div className="main-content">
        <Branches studioSetter={this.studioSetter} />

        <SchedulerAlert 
        variant="info" 
        text="💡Lai rezervētu mēģinājumu, klikšķiniet uz brīvā laika kalendārā" 
        show={true}
        />
        
        <SchedulerAlert 
        variant="danger" 
        text="Ar atpakaļejošu datumu un laiku rezervēt nav iespējams" 
        show={this.state.alertChosenPast}
        />

        <Scheduler
          customEditor={(scheduler) => (
            <CustomEditor
              hideBackdrop={true}
              scheduler={scheduler}
              studioSelected={this.state.studioSelected}
              rehearsalTypes={this.state.rehearsalTypes}
              additionalServices={this.state.additionalServices}

              earlierThanNowClicked={() => {
                this.setState({alertChosenPast: true}, () => {
                })
              }}

            />
          )}
          events={this.state.reservations}
          locale={lv}
          month={month}
          week={week}
          day={day}
          onConfirm={submitEventHandler}
        />
      </div>
    );
  }
}
